.toolBeltTable {
  .table {
    color: #2d5d7e;
    font-weight: normal;
  }
}

.tablePagination {
  display: flex;
  justify-content: flex-end;
}

table.toolBeltTable thead tr {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;

  background-color: #FFFFFF;
}
