@import '../../color.scss';

.container {
  color: $tech-blue;
  textarea {
    color: $tech-blue;
  }

  .row {
    padding-bottom: 10px;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
}
