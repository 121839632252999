@import '../../Color';

$accent-color: #e48;

.radio-group {
  padding: 1em;

  label {
    display: block;
    padding: .5em;
    cursor: pointer;

    border: 1px solid $gray-500;
    color: $tech-blue;

    > span {
      display: grid;
      grid-template-columns: 35px 1fr 35px;
    }

    > span:before {
      content: "";
      display: inline-block;
      vertical-align: -.25em;
      height: 1.3em;
      width: 1.3em;
      border-radius: 50%;
      border: 2px solid $gray-700;
      margin-right: .5em;
      margin-top: 0.1em;
    }

    > input:checked + span:before {
      border-color: $gray-700;
      background-image: radial-gradient(
                      circle,
                      $orange 0%,
                      $orange 90%,
                      transparent 50%,
                      transparent 100%);
    }

    > input:disabled + span { opacity: .5; }

    > input:disabled:checked + span:before {
      background-image: radial-gradient(
                      circle closest-side,
                      rgba(0, 0, 0, 0.5) 0%,
                      rgba(0, 0, 0, 0.5) 50%,
                      transparent 50%,
                      transparent 100%);
    }

    > input { display: none; }

    .icon-lock {
      justify-self: end;
    }
  }
}

.button-group {
  padding: 0.5em;

  &:before, &:after {
    content: "";
    display: table;
  }

  &:after { clear: both; }

  label {
    float: left;
    cursor: pointer;

    > span {
      padding: 5px 15px;

      color: #2c354f;
      border: 1px solid #2c354f;

      font-weight: bold;

      text-transform: uppercase;

      margin-left: 1rem;

      &:focus, &:hover {
        background-color: #2c354f;
        color: $gray-100;;
      }
    }

    //&:not(:last-of-type) > span { border-right: 0; }
    //
    //&:first-of-type > span { border-radius: 2px 0 0 2px; }
    //
    //&:last-of-type > span { border-radius: 0 2px 2px 0; }

    > input:checked + span {
      background-color: #2c354f;
      color: $gray-100;
    }

    > input { display: none; }
  }
}
