@import '../../../../color.scss';

.detail-location-container {
  display: grid;
  grid-template-columns: 80px 1fr;
  grid-template-areas: "icon place"
                       "count count";

  padding: 10px 0 0 0;

  .icon {
    height: 75px;
    grid-area: icon;
  }

  .detail-container {
    grid-area: place;

    .detail-label {
      font-size: 15px;
      font-weight: 600;
      padding-top: 10px;
    }

    .detail-location-more {
      font-size: 12px;
      padding-left: 2px;
      color: $gray-800;
    }

    .detail-location {
      font-size: 10px;
      font-style: italic;
      padding-top: 5px;
      color: $gray-600;
    }
  }

  .count {
    grid-area: count;
    width: 100%;

    .detail-populous-place {
      font-weight: 400;
      font-size: 13px;
      font-style: italic;
      text-align: center;
    }

    .detail-trade {
      font-size: 15px;
      font-weight: 600;
      padding-top: 10px;
      text-align: center;
    }

    .detail-count {
      text-align: center;
      font-weight: 400;
      font-size: 13px;
    }

    .count-color {
      font-weight: 600;
      color: $orange;
    }
  }
}


