.tag-cloud-view-container {
  display: grid;
  //width: 100px;
  min-height: 100px;

  .image-cloud {
    width: 100%;
    height: 100%;

    display: grid;
    align-content: center;

    background-color: #ebebeb;
  }
}

.tag-1-image {
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas: "image-1";

  margin-top: 20px;
}

.tag-2-image {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "image-1 image-2";
  grid-gap: 10px;
}

.tag-3-image {
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: ". image-1 ."
                       ". . ."
                       "image-2 . image-3";
}

.tag-4-image {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "image-1 image-3"
                       "image-2 image-4";
  grid-gap: 10px;
}

.tag-5-image {
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "image-2 . image-3"
                       ". image-1 ."
                       "image-4 . image-5";
  grid-gap: 5px;
}

.tag-6-image {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: ". image-1 image-2 ."
                       "image-3 . . image-4"
                       ". image-5 image-6 .";
  grid-row-gap: 15px;
  grid-column-gap: 5px;
}

.tag-7-image {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: ". image-1 image-2 ."
                       "image-3 image-7 . image-4"
                       ". image-5 image-6 .";
  grid-row-gap: 15px;
  grid-column-gap: 5px;
}

.tag-8-image {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: ". image-1 image-2 ."
                       "image-3 image-7 image-8 image-4"
                       ". image-5 image-6 .";
  grid-row-gap: 15px;
  grid-column-gap: 5px;
}

.image-1 {
  grid-area: image-1;
}

.image-2 {
  grid-area: image-2;
}

.image-3 {
  grid-area: image-3;
}

.image-4 {
  grid-area: image-4;
}

.image-5 {
  grid-area: image-5;
}

.image-6 {
  grid-area: image-6;
}

.image-7 {
  grid-area: image-7;
}

.image-8 {
  grid-area: image-8;
}
