@import '../../Color';

.datePicker {

  .date-input-icon {
    display: none;
  }
  .react-datepicker__day,
  .react-datepicker__day-name,
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header{
    color: $tech-blue;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    color: $white;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .date-input-layout {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
  }
}
