@import '../../color.scss';

.action-list-gutter {
  padding: 15px;

  .header {
    font-style: italic;
  }
}

.action-list-header {
  padding-left: 15px;
  display:flex;
  flex-direction:row;
  width:800px;
  gap: 10px;
  align-items: baseline;
  margin-top: 8px;
  margin-bottom: 8px;
}

.action-list-container {
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 8px;
  padding: 1rem;
  margin-top: 1.5rem;
  //height: calc(100vh - 245px);
}

.action-list-form-container {
  color: $tech-blue;
  textarea {
    color: $tech-blue;
  }

  .row {
    padding-bottom: 10px;
    align-items: baseline;
  }

  .action-list-form-label {
    text-align: left;
    padding-right: 10px;
  }

  .panel {
    align-items: baseline;
  }

  .buttons {
    text-align: left;
  }

}

.spinner-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 15px;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.15); /* Black background with opacity */
  z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

