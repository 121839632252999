.dropdown-toggle {
  margin: auto !important;
}

.dropdown {
  margin: auto !important;
}

.btn-outline-secondary.dropdown-toggle:focus {
  color: white !important;
}

.btn-outline-secondary.dropdown-toggle:hover {
  color: white !important;
}
