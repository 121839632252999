@import '../../Color';

$color__back: #FFFFFF;
$color__back--high: lighten($color__back, 20%);
$color__text: #fff;
$color__text--low: darken($color__text, 50%);
$color__primary: #5dceee;
$color__disabled: #CCCCCC;

$radio__size: 15px;
$radio_radius: 0.5rem;

$radio__border__size: 1px;
$radio__border__color: $orange;
$radio__border__color--disabled: $color__disabled;

$radio__checkmark__size: $radio__size - (1*$radio__border__size);
$radio__checkmark__size--indeterminate: 2px;
$radio__checkmark__color: $orange;
$radio__checkmark__color--disabled: $color__disabled;

$radio__label__color: $color__text;
$radio__label__color--disabled: $color__text--low;

// CHECKBOXES
.exploreAccuracyScoreRadioGroupForm {
  .form-check {
    //display: flex;
    //align-items: center;

    label {
      flex-shrink: 0;
      padding: .5rem;
      color: $tech-blue;
      cursor: pointer;
    }

    input {
      margin-top: 0; // appears to center the radio a bit better
      position: relative;
      flex-shrink: 0;
      width: $radio__size;
      height: $radio__size;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      outline: none; // TODO: manage :focus
      background: $color__back;
      cursor: pointer;
      border: $radio__border__size solid $gray-400;
      border-radius: $radio_radius;

      &::before {
        content: ' ';
        position: absolute;
        top: 50%;
        right: 50%;
        bottom: 50%;
        left: 50%;
        transition: all .1s;
        background: $radio__checkmark__color;
        border-radius: $radio_radius;
      }

      &:checked,
      &:indeterminate {
        &::before {
          top: $radio__border__size;
          right: $radio__border__size;
          bottom: $radio__border__size;
          left: $radio__border__size;
        }
      }

      &:indeterminate {
        &::before {
          top: ($radio__checkmark__size / 2) - ($radio__checkmark__size--indeterminate / 2);
          bottom: ($radio__checkmark__size / 2) - ($radio__checkmark__size--indeterminate / 2);
        }
      }

      &:disabled {
        border-color: $radio__border__color--disabled;
        cursor: default;

        &::before {
          background-color: $radio__checkmark__color--disabled;
        }

        + .m-radio__label {
          color: $radio__label__color--disabled;
          cursor: default;
        }
      }
    }
  }
}
