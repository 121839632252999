.lists-container {
  padding: 15px;
}

.lists-table {
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 8px;
  padding: 1rem;
  margin-top: 1.5rem;

  height: calc(100vh - 245px);
}

.listToolbar {
  padding: 10px;
}

.searchBar {
  display: grid;
  grid-template-columns: 12fr 1fr;
  grid-column-gap: 10px;

  .listSearchText {
    justify-self: self-start;
    align-self: center;
  }

  .listAction {
    justify-self: self-end;
  }
}

.orgListToolbar {
  padding: 10px;
}

.description {
  white-space: nowrap;
  width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.count {
  width: 200px;
}
