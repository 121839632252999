@import '../../color.scss';

.mapContainer {
  //position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  min-height: calc(100vh - 255px);
}

.sidebarStyle {
  display: inline-block;
  position: fixed;
  //top: 0;
  right: 0;
  bottom: 0;
  margin-right: 10px;
  margin-bottom: 20px;
  background-color: #404040;
  color: #ffffff;
  z-index: 1 !important;
  padding: 6px;
  font-weight: bold;
}

.explore-map-spinner-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 15px;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.15); /* Black background with opacity */
  z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.explore-map-action-bar-and-legend {
  position: absolute;
  top: 10px;
  z-index: 1 !important;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.explore-map-action-bar-and-legend-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: $white;
  width: 20%;
}

.explore-map-flyout-hide {
  position: absolute;
  width: 45px;
  height: 45px;
  right: 25px;
  top: 10px;
  background-color: $white;
  z-index: 99;
  padding: 5px 10px;
  font-size: 24px;
  // opacity: 0.5;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 10px;
}

.mapboxgl-ctrl-geocoder--input {
  color: black !important;
}