/*
Override some default styles
 */
// FIXME find the best way to define variable.
@import './Color.scss';
//------------------

.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  padding-left: 0px;
  padding-right: 0px;
}

.btn {
  text-transform: uppercase;
  font-weight: bold;
  padding: 5px 15px;
}

.btn-min-width-120 {
  min-width: 120px;
}

.btn-min-width-150 {
  min-width: 150px;
}

.btn-min-width-200 {
  min-width: 200px;
}

.btn-height-35 {
  height: 35px;
}

.btn-toggle {
  width: 40px;
  height: 40px;
  border-radius: 20px !important;
  padding: 7px;

  margin-right: 10px;
}

.btn-toggle.active {
  background-color: $orange !important;
  color: $white !important;
}

input {
  color: $tech-blue !important;
}

// Menu
.navbar-bordered {
  border-width: 0 0 .0625rem 0;
  border-style: solid;
  border-color: #e7eaf3;
  background-color: #f8f9fa;
}

.navbar-light .navbar-nav .nav-link {
  color: $tech-blue;
}

// Panels
.panel {
  padding: .5rem;
}

.horizontal-divider{
  margin-top: 10px;
  margin-bottom: 10px;
  height: 1px;
  border-top: 1px solid #2d5d7e;
}

//Toggle Panel
.accordionWrapper {
  & + * {
    margin-top: 0.5em;
  }
}

.accordionItem {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
}

.accordionItem.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordionTitle {
  font-weight: 600;
  cursor: pointer;
  color: #2d5d7e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa;
  //border-width: 0.0625rem;
  //border-style: solid;
  //border-color: #e7eaf3;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 0px;
  &:hover,
  &.open {
    color: #2d5d7e;
  }
}

.accordionContent {
  background-color: transparent;
  overflow-y: auto;

  padding: 10px;
  font-size: 12px;
  text-transform: uppercase;
}

.accordionHeaderContent {
  display: flex;

  justify-content: space-between;
  align-items: center;

  padding-right: 20px;

  width: 100%;
}

.accordionSelectContent {
  margin: 5px 0 0 10px;
}

.accordionShowMoreContent {
  display: flex;
  justify-content: flex-end;
}

.title {
  width: 100%;
  padding: 0.5em 0.5em 0.5em 0.7em;

  font-size: 12px;
  font-weight: bold;

  text-transform: uppercase;;
}

.expandIcon {
  padding: 0.4em 0.7em;
  font-size: 17px;
}

// Drop Down

.dropdown-toggle {
  font-weight: bold;
  text-transform: uppercase;
}
.dropdown-menu {
  padding: 0.3rem 0;
}

.dropdown-item {
  background-image: none;
  color: $tech-blue;

  font-size: 14px;
  padding: 0.25rem 0.5rem;
}

//sidebar
$pink: #FF65C3;
$faded-white: #FAFAFC;
$dark-grey: #E5E5E7;
$yellow: #EFB05A;
$white: #FFFFFF;

$sidebar-width: 350px;

main {
  display: flex;
  background: $white;
  width: 100%;
  height: 100%;
  position: relative;

  overflow: hidden;
}

.sidebar {
  width: $sidebar-width;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transform: translateX(-320px);
  transition: 300ms ease-in-out;
  z-index: 1;
  //overflow-x: auto;

  padding: 10px 30px 10px 10px;

  $panel-table-calc: 0px;

  &--expanded {
    background-size: 100%;
    background-position: 0 0;
    transform: translateX(0);

    $panel-table-calc: 360px;
  }

  &:hover {
    background-position: 0 0;
  }

  .toggle_box {
    font-size: 25px;
    padding-left: 3px;
    position: absolute;
    cursor: pointer;

    width: 17px;
    height: 35px;

    right: 13px;
    top: 60px;

    background-color: $white;

    z-index: 99999;

    box-shadow: rgba(0, 0, 0, 0.1) 5px 0px 6px;
  }

  .sidebar-content {
    height: 100%;
    padding: 0;
    background-color: $white;

    //box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    //overflow-x: auto;
  }
}

.main-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 1% 10px 0.5%;
  margin-left: 1.5rem;
  transition: 300ms ease-in-out;
  position: relative;
  z-index: 2;

  height: calc(100vh - 60px);

  &--expanded {
    margin-left: 340px;
  }
}

// explore page
.exploreToolBar {
  height: 40px;
}

.exploreTool {
  display: grid;
  grid-template-columns: 1fr 100px;
}

.exploreToolAction {
  display: grid;
  grid-template-columns: 130px 130px 130px 300px 1fr;
  grid-gap: 10px;
}

.exploreToolSwitch {
  text-align: right;
}

.form-group {
  margin-bottom: 5px;
}

.formTextLabel {
  font-weight: 600;
  color: #2d5d7e;
  align-items: center;
}

.home {
  color: $tech-blue;
  font-weight: bold;
}

// Table
.table-panel {
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 8px;
  padding: 1rem;
  margin-top: 1.5rem;

  width: calc(100vw - 45px);
  height: calc(100vh - 265px);
  transition: width 300ms ease-in-out;
  overflow-y: hidden;
  overflow-x: auto;
}

.cards-panel {
  padding: 1rem;
  margin-top: 1.5rem;

  width: calc(100vw - 45px);
  height: calc(100vh - 265px);
  transition: width 300ms ease-in-out;
  overflow-y: hidden;
  overflow-x: auto;
}

.main-content--expanded .table-panel {
  width: calc(100vw - 360px);
}

.table-responsive {
  height: calc(100vh - 345px);
  margin-bottom: 15px;
}

.table {
  color: $tech-blue;
}

.table thead th, .table td {
  border-top: none !important;
  border-bottom: 1px solid $gray-400;
}

.table thead th, .table thead td {
  border-bottom-width: 2px;
}

.table thead td {
  font-weight: 600;
  font-size: 14px;
  padding: 10px 5px;
}

.table tbody td {
  font-size: 13px;
  padding: 20px 10px;
}

.table-icon-container {
  height: 45px;
  width: 45px;

  padding: 4px;

  border: 1px solid $gray-400;
}

.table-icon {
  height: 35px;
}

// Tab content
.nav {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 8px;
  background-color: $white;
  color: $tech-blue;

  .nav-item {
    width: 50%;

    font-weight: 700;
    font-size: 14px;

    text-align: center;

    border: none;
    padding: 0.3rem 1rem;
  }

  .active {
    border-bottom: 3px solid $orange !important;
  }
}
.tab-content {
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 8px;
  background-color: $white;

  padding: 10px;

  height: calc(100vh - 115px);
  overflow-x: auto;
}

// Filters Panel
.filters-panel {
  display: grid;
  grid-template-columns: 170px 1fr 150px;

  padding-top: 10px;
}

.filters-applied-container {
  width: calc(100vw - 700px);
}

.filters-applied {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: absolute;
  width: calc(100vw - 380px);

  height: 50px;
  max-height: 50px;
  overflow: hidden;

  transition: all 300ms ease-in-out;

  padding-right: 85px;
}

.main-content--expanded .filters-applied {
  width: calc(100vw - 700px);
}

.filters-applied-more {
  max-height: fit-content;
  height: fit-content;
  background-color: white;
  z-index: 9999;
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 8px;

  padding: 10px 85px 0 0;
}

.show-action {
  position: absolute;
  height: 40px;
  right: 5px;

  color: $tech-blue;
  cursor: pointer;

  button {
    font-size: 13px;
    padding-top: 13px;
  }
}

.carousel-indicators {
  bottom: -40px;

  li {
    width: 5px;
    height: 5px;

    background-color: $tech-blue;
    border: 1px solid;
    border-radius: 10px;
  }
}

.rbt-token-removeable {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

// Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;700&display=swap');
// React Vis
@import "~react-vis/dist/style";
