.sectionAccordionTitle {
  font-weight: 600;
  cursor: pointer;
  color: #2d5d7e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  //border-style: solid;
  //border-color: #e7eaf3;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 0px;
  &:hover,
  &.open {
    color: #2d5d7e;
  }
}

.sectionHorizontalDivider{
  height: 1px;
  border-top: 1px solid #2d5d7e;
  margin-left: 10px;
  margin-right: 10px;
}
