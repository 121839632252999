@import '../../color.scss';

.search-results-map-container {
  //position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  min-height: 500px;
  //min-height: calc(60vh);
}

.mapboxgl-ctrl-geocoder--input {
  color: black !important;
}
