.color-selector-editing {
  display: inline-block;
  margin: 0 auto;
  border-radius: 4px;
  position: relative;
  padding: 5px 30px 5px 7px;
  background: white;
  width: 100%;
  border: 1px solid #CCC;

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 5px 0 5px;
    border-color: black transparent transparent transparent;
    position: absolute;
    right: 10px;
    top: 14px;
  }

  .circle {
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid #eee;
    margin-right: 10px;
  }

  .hidden {
    position: absolute;
    left: 0;
    opacity: 0;
  }

  span {
    display: inline-block;
    vertical-align: middle;
  }
}

.color-selector {
  display: inline-block;
  margin: 0 auto;
  border-radius: 4px;
  position: relative;
  padding: 5px 30px 5px 7px;
  width: 100%;
  border: 1px solid #CCC;

  .circle {
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid #eee;
    margin-right: 10px;
  }

  .hidden {
    position: absolute;
    left: 0;
    opacity: 0;
  }

  span {
    display: inline-block;
    vertical-align: middle;
  }
}


.color-palette {
  overflow: hidden;
  position: relative;
}
