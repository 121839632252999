@import '../../../color.scss';

.upload-dialog {
  width: 700px !important;
  max-width: 700px !important;

  .header {
    display: flex;
    justify-content: space-between;
    color: $tech-blue;
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .multiSteps {
    color: $tech-blue;

    .navigator {
      list-style-type: none;
      padding: 0;

      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

      margin-bottom: 5px;

      .step {
        display: grid;
        justify-content: center;

        font-size: 14px;
        text-align: center;

        padding: 0.6rem 0;

        cursor: pointer;

        border-bottom: 1px solid silver;
      }

      .step-active {
        @extend .step;
        border-bottom: 3px solid $orange;
        font-weight: bold;
      }
    }

    .container {
      padding: 1rem;
      height: 330px;

      overflow: hidden;
    }

    .listTypeLabel {
      margin-left: 20px;
    }

    .inputListSelectionContent {
      margin-left: 20px;

      .label-text {
        align-self: center;
      }

      .source-image {
        display: grid;
        justify-items: center;
        text-align: center;
        width: 100%;
        grid-template-columns: 1fr;

        label {
          margin-bottom: 0;
          font-size: 14px;
          cursor: pointer;
        }
      }

      .source-file {
        display: grid;
        justify-items: center;
        text-align: center;
        width: 100%;
        grid-template-columns: 1fr;

        .file-name {
          color: $orange;
          font-weight: bold;
        }

        .source-file-type {
          width: 100%;
          margin-top: 20px;

          font-size: 15px;

          .col-form-label {
            padding-left: 0;
            text-align: left;

            label {
              margin-bottom: 0;
            }
          }
        }
      }

      .source-text-input {
        label {
          font-weight: bold;
          font-size: 14px;
        }
      }

      textarea {
        resize: none;
      }
    }

    .nameListSelectionContent {
      margin-left: 20px;

      textarea {
        resize: none;
      }
    }

    .mappingContainer {
      padding: 0;
      margin-top: -25px;

      font-size: 14px;
    }

    .mappingHeader {
      font-weight: bold;
      text-align: left;

      padding: 10px;
      margin-bottom: 5px;

      border-bottom: 2px solid silver;
    }

    .mappingRecordContainer {
      height: 250px;
      overflow-x: auto;
    }

    .mappingRecord {
      text-align: left;
      margin-left: 0;
      margin-right: 0;

      padding: 5px 0;
    }

    .mapping-spinner {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .upload-review-container {
      font-size: 14px;
      font-weight: 600;

      padding: 0 10px;

      .upload-review {
        display: grid;
        grid-auto-rows: 50px;
      }

      .alert {
        margin-top: 70px;
      }
    }
  }
}


