@import '../../color.scss';

.dialog {
  width: 700px !important;
  max-width: 700px !important;

  .modal-content .modal-header {
    border: 1px none $gray-500;
    border-bottom-style: solid;
  }

  .header {
    display: flex;
    justify-content: space-between;
    color: $tech-blue;
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
