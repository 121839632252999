@import '../../color.scss';

.address-editor {
  height: 200px;
  width: 500px;
  background-color: white;
}

.addressFormInput {
  display: block;
  margin: 0px 0px 15px 20px;
  padding: 4px;
  width: 80%;
  height: 5%;
  box-sizing: border-box;
  border: 1px solid #aaa;
  outline: none;
  // border-bottom: 1px solid #aaa;
  font-size: 12px;
}
.addressFormInput.input {
  border: none;
  outline: none;
  font-size: 12px;
}

.recordValidation-container {
  padding: 15px;
}

.recordValidation-table {
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 8px;
  padding: 1rem;
  margin-top: 1.5rem;

  height: calc(100vh - 245px);
}

.recordValidationToolbar {
  padding: 10px;
}

.searchBar {
  display: grid;
  grid-template-columns: 12fr 1fr;
  grid-column-gap: 10px;

  .recordValidationSearchText {
    justify-self: self-start;
    align-self: center;
  }
  .recordValidationAction {
    justify-self: self-end;
  }
}

.description {
  white-space: nowrap;
  width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.count {
  width: 200px;
}

//.add-member-container {
//  padding: 20px !important;
//
//  .add-member-label {
//    text-align: right;
//    padding-right: 10px;
//  }
//}

.create-modal-container {
  color: $tech-blue;
  textarea {
    color: $tech-blue;
  }

  .row {
    padding-bottom: 10px;
  }

  .create-modal-label {
    text-align: right;
    padding-right: 10px;
  }
}

.create-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.spinner-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 15px;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.15); /* Black background with opacity */
  z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.recordValidation-owner-active-email {
  color: #6da06f;
}

.recordValidation-owner-inactive-email {
  color: #c13727;
}
