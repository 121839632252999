@import "../../Color.scss";

.modal-backdrop {
  background-color: $white !important;
  opacity: 0.7 !important;;
}

.modal-content {
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 9px 2px;
  border: none !important;

  .modal-header {
    color: $tech-blue;
    border: none;

    .modal-title {
      text-transform: uppercase;

      font-size: 16px;
      font-weight: bold;

      margin: 10px 0 10px 10px;
    }

    .close {
      color: $tech-blue;
    }
  }

  .modal-body {
    min-height: 350px;
    padding: 0;
  }

  .modal-footer {
    border-top-color: $gray-500;
    .footer {
      .btn {
        min-width: 120px;
      }

      .btn-primary {
        color: $white;
        background-color: $orange;
        margin-left: 10px;
      }

      .btn-secondary {
        color: $tech-blue;
        background-color: $white;
      }
    }
  }
}
