@import '../../color.scss';

.LockedFilterContainer {
  display: grid;
  grid-template-columns: 1fr 40px;
  grid-template-areas:
      "locked-filter-name locked-filter-action"
      "locked-filter-detail locked-filter-action";
  height: 45px;

  .locked-filter-name {
    grid-area: locked-filter-name;

    font-size: 0.5rem;
    color: $gray-600;

    padding: 0.2rem;
  }

  .locked-filter-detail {
    grid-area: locked-filter-detail;

    font-size: 0.75rem;
    color: $gray-600;

    padding: 0.2rem;

    height: 25px;
  }

  .locked-filter-action {
    grid-area: locked-filter-action;
  }

  .locked-filter-detail-button {
    cursor: pointer;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  .locked-filter-detail-popup {
    font-size: 12px;
    width: 270px;

    padding: 5px;
  }

  .locked-filter-detail-tooltip {
    padding: 5px !important;
    background-color: white !important;
    color: $gray-600 !important;
    opacity: 1.0 !important;

    box-shadow: rgba(0, 0, 0, 0.15) 0 0 8px;

    &:before {
      display: none;
    }

    &:after {
      display: none;
    }
  }
}



