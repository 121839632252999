@import '../../color.scss';

.explore-map-flyout {
  position: absolute;
  width: 300px;
  min-height: 200px;
  right: 25px;
  top: 10px;
  background-color: $white;
  z-index: 99;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 10px;

  .close-box {
    position: absolute;
    width: 20px;
    height: 30px;
    left: -20px;
    top: 10px;
    background-color: $white;
    box-shadow: rgba(0, 0, 0, 0.15) -7px 0px 10px;
    cursor: pointer;
  }

  .flyout-header {

    .label-text {
      font-size: 14px;
      text-transform: uppercase;
      color: $tech-blue;
      font-weight: bold;
    }

    .overlay-typeahead {
      margin-top: 5px;
    }

    .accordion-toggle-container {
      margin-top: 5px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

    }

    .accordion-toggle-button {
      padding: 0;
      color: $orange;
    }

    .accordion-body-container {

      .action-container {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .action-button {
          color: $tech-blue;
          margin: 0;
          text-transform: none;
          font-size: 12px;
          font-weight: 400;
        }

      }

    }

    .btn:focus, .btn.focus, .btn-link:hover, .btn-link.hover, .btn-link:focus, .btn-link.focus {
      box-shadow: none;
      text-decoration: none;
    }
  }
}