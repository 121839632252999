@import '../../Color.scss';

.rangeSliderContainer {
  padding: 10px;
  margin: 20px 0;

  .rangeSliderLabel {
    display: grid;
    grid-template-columns: 1fr 1fr;

    margin: 0 -10px 20px -10px;

    color: $tech-blue;
  }

  .rangeSliderNameLabel {
    justify-self: flex-start;
    font-weight: 600;
  }

  .rangeSliderMinMaxLabel {
    justify-self: flex-end;
    font-weight: 600;
  }


  .input-range {
    .input-range__track--active {
      background-color: $orange;
    }

    .input-range__slider {
      background-color: $orange;
      border-color: $orange;
    }

    .input-range__label-container {
      display: none;
    }
  }

}

