@import '../../color.scss';

.create-input-gutter {
  padding: 15px;

  .header {
    font-style: italic;
  }
}

.create-input-container {
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 8px;
  padding: 1rem;
  margin-top: 1.5rem;
  //height: calc(100vh - 245px);
}

.create-input-form-container {
  color: $tech-blue;
  textarea {
    color: $tech-blue;
  }

  .row {
    padding-bottom: 10px;
  }

  .create-input-form-label {
    text-align: right;
    padding-right: 10px;
  }

  .buttons {
    text-align: right;
  }

}

.spinner-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 15px;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.15); /* Black background with opacity */
  z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.upload-container {
  .source-image {
    display: grid;
    justify-items: center;
    text-align: center;
    width: 100%;
    grid-template-columns: 1fr;

    label {
      margin-bottom: 0;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .source-file {
    display: grid;
    justify-items: center;
    text-align: center;
    width: 100%;
    grid-template-columns: 1fr;

    .file-name {
      color: $orange;
      font-weight: bold;
    }

    .source-file-type {
      width: 100%;
      margin-top: 20px;

      font-size: 15px;

      .col-form-label {
        padding-left: 0;
        text-align: left;

        label {
          margin-bottom: 0;
        }
      }
    }
  }
}

.mapping-container {
  .mapping-header {
    font-weight: 600;
    text-align: left;
    padding: 10px;
    margin-bottom: 5px;
    border-bottom: 2px solid $gray-300;
  }

  //.mapping-auto-fill-button {
  //  display: flex;
  //  justify-content: center;
  //  align-items: center;
  //  font-style: italic;
  //}

  .mapping-record-container {
    min-height: 300px;
    overflow-x: auto;

  }

  .mapping-record {
    font-size: 14px;
    border-bottom: 2px solid $gray-300;
    padding: 5px 0;
  }

  .mapping-record-display {
    display: flex;
    justify-content: left;
    align-items: center;
    //text-align: right;
  }

  .mapping-record-status {
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
  }
}
