@import '../../Color.scss';

.SvgSpritesIconPicker {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: $gray-100;
}

.svgIconItem {
  background-color: #f9f9f9;
  border: 1px solid #fff;
  color: black;
  display: block;
  flex: 1 1 auto;
  font-size: 12px;
  line-height: 1.4;
  min-height: 50px;
  padding: 10px;
  text-align: center;
  user-select: none;
}
.svgIconItem :hover {
  background-color: #2d5d7e;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}

.popover {
  position: absolute;
  zIndex: 2;
}
