
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  background-color: white !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-bottom: 5px !important;
}
.breadcrumb-item {
  + .breadcrumb-item::before {
    content: ">" !important;
    align-self: baseline;
    color: #7d8daa;
  }
}
