@import '../../color.scss';

.AppliedFilterContainer {
  display: grid;
  grid-template-columns: 1fr 40px;
  grid-template-areas:
      "filter-name filter-action"
      "filter-detail filter-action";
  height: 45px;
}

.filter-name {
  grid-area: filter-name;

  font-size: 0.5rem;
  color: $gray-600;

  padding: 0.2rem;
}

.filter-detail {
  grid-area: filter-detail;

  font-size: 0.75rem;
  color: $gray-600;

  padding: 0.2rem;

  height: 25px;
}

.filter-action {
  grid-area: filter-action;
}

.detail-button {
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.detail-popup {
  font-size: 12px;
  width: 270px;

  padding: 5px;
}

.detail-tooltip {
  padding: 5px !important;
  background-color: white !important;
  color: $gray-600 !important;
  opacity: 1.0 !important;

  box-shadow: rgba(0, 0, 0, 0.15) 0 0 8px;

  &:before {
    display: none;
  }

  &:after {
    display: none;
  }
}
