@import '../../color.scss';

.dialog {
  width: 700px !important;
  max-width: 700px !important;

  .modal-content .modal-header {
    border: 1px none $gray-500;
    border-bottom-style: solid;
  }

  .header {
    display: flex;
    justify-content: space-between;
    color: $tech-blue;
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.swatch {
  padding: 5px;
  background: #fff;
  borderRadius: 1px;
  boxShadow: 0 0 0 1px rgba(0,0,0,.1);
  display: inline-block;
  cursor: pointer;
}
.popover {
  position: absolute;
  zIndex: 2;
}

.cover{
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.container {
  color: $tech-blue;
  textarea {
    color: $tech-blue;
  }

  .row {
    display: flex;
    justify-content: space-between;
    margin: 15px 0 0 0;
  }
}
