@import '../../../../color.scss';

.explore-map-flyout-detail {
  margin-top: 10px;
  border-top: 1px solid $gray-400;
  overflow: auto;
  max-height: calc(100vh - 430px);
  //display: grid;
  //grid-template-columns: 1fr ;
  color: $tech-blue;

  .no-selected-point-detail-text-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .header-text-container {
    .header-text {
      font-size: 10px;
      font-weight: 400;
      font-style: italic;
    }
  }

  .icon-container {
    display: flex;
    flex-direction: row;
  }

  .icon {
    height: 75px;
  }

  .lead-text-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .lead-text-items {
    display: flex;
    flex-direction: column;
  }

  .lead-text-label {
    font-size: 14px;
    font-weight: 600;
    padding-left: 5px;
  }

  .lead-text-count {
    font-size: 2.5rem;
    padding-left: 5px;
    color: $orange
  }

  .lead-text {
    font-weight: 400;
  }

  .lead-text-small {
    font-size: 10px;
    padding-left: 5px;
    font-style: italic;
    color: $gray-600;
  }

  .detail-text-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .detail-text {
    font-size: 14px;
  }

  .density-icons-container {
    display: flex;
    flex-direction: row;
    padding-left: 5px;
  }

  .action-container {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .action-button {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 12px;
  }

  //.selected-point-detail-record-detail-text-container {
  //  margin-top: 10px
  //}

  // No Selected Point
  .no-selected-point {
    .icon-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .detail-text-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }

  .selected-point-chart-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .selected-data-point-action-container {
    display: flex;
    flex-direction: row;
  }

  .selected-data-point-action-button {
    font-size: 11px;
    font-weight: 400
  }

  .selected-map-point {
    text-align: center;

    padding: 5px;
    font-size: 12px;
  }

  // Carousel

  .carousel-container {
    margin-top: 10px;
    height: auto;

    .carousel-card-header-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .carousel-card-header-text {
      font-size: 16px;
      font-weight: 600;
    }

    .carousel-card-detail-container {
      padding-left: 30px;
      padding-right: 30px;
      height: 120px;

      .carousel-card-top-down-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .lead-text {
          font-size: 20px;
          weight: bold;
          color: $gray-600;
        }

        .lead-sub-text {
          font-size: 14px;
          color: $gray-600;
        }
      }

      .carousel-card-top-down-items-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      .carousel-card-chart-container {
        margin-top: 10px;
        padding-left: 30px;
        padding-right: 30px;
        height: 120px;
      }
    }
  }

  .selected-point-chart-container {
    margin-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
    height: 120px;
  }

  .detail-text-label {
    font-size: 14px;
    font-weight: 600;
  }

  .detail-text-value {
    font-size: 14px;
    color: $orange
  }

  .selected-location-point-geospatial-search-container {
  }

  .selected-location-map-tools {

    .section-icon-container {
      display: flex;
      flex-direction: row;
      padding: 0.5em 0.5em 0.5em 0.7em;
    }

    .section-icon {
      height: 20px;
    }

    .section-header {
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      color: $tech-blue;
      padding-left: 5px;
    }
  }

  .flyout-map-tools-section {
    padding-top: 10px;
    .label-text {
      font-size: 14px;
      text-transform: uppercase;
      color: $tech-blue;
      font-weight: bold;
    }

    .map-tools-typeahead {
      margin-top: 5px;
    }
  }

  .flyout-detail-section {
    padding-top: 10px;
  }
}

.explore-map-flyout-detail-data-point {
  //border: none;
  // margin-top: 0;

  // unuse
  .selected-data-point-back-container {
    button {
      padding-left: 0;
      text-align: left;

      font-weight: 600;
      font-size: 12px;
    }
  }

  .selected-point-detail-record-info-icons-container {
    display: flex;
    flex-direction: row;

    padding-left: 5px;
  }

  // unuse
  .selected-point-detail-record-density-icons-container {
    margin-top: 15px;

    display: flex;
    flex-direction: row;

  }

  .selected-point-detail-record-badges-icons-container {
    margin-top: 15px;
    margin-bottom: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .selected-point-detail-record-badges-icons-item {
    margin-left: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 0.5px solid #2d5d7e;

    padding-left: 5px;
    padding-right: 10px;
  }

  .selected-point-action-container {
    text-align: center;
    margin-top: 30px;
  }

  .selected-point-detail-text-container {
    text-align: center;
  }
}

.selected-point-carousel {

  .carousel-header-container {
    text-align: center;
    margin-bottom: 20px;

    font-weight: 600;
  }

  .carousel-top-down-kpi-container {
    .trades-kpi-icon {
      height: 40px;
      margin-bottom: 10px;
    }

    .lead-text {
      font-weight: 600;
    }

    .lead-sub-text {
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.carousel-detail-kpi-container {
  height: auto;
}

.lead-sub-text {
  font-size: 14px;
  font-weight: 600;
}

